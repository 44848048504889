import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  ListWrapper,
  PageTitle,
  LinkWrapper,
  ListItems,
  ListHeaderItem,
} from "../../PageWithList/List/Styles";
import ListHeader from "../../PageWithList/List/ListHeader";
import CreateButton from "../../Dashboard/UI/CreateButton";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import ProGateway from "../../Modals/AuthGatewayModals/ProGateway";

import ActionButton from "../../PageWithList/Sheet/ActionButton";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import { NoticeWrapper } from "../../UI/SignUpNotice/styles"
import usersAdd from '../../../assets/icons/userAdd.svg';

const RoundtripListHeader = (props) => {
  const { USER } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [isRegular, setIsRegular] = useState(false);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [proModalOpen, setProModalOpen] = useState(false);

  let USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  );
  const SUBSCRIPTION_NAME = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.subscriptionName
  );
  const EXPIRATION_DATE = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.expirationDate
  );
  const SUBSCRIPTION_STATUS = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get('create');
  
  useEffect(() => {
    setIsExpired(true)
    if(SUBSCRIPTION_NAME === 'Roundtrip Pro') {
      if(SUBSCRIPTION_STATUS === 'Active' || SUBSCRIPTION_STATUS === 'Cancelled') {
        if(moment(EXPIRATION_DATE).isSameOrAfter()){
          setIsExpired(false)
        }
        
      }
    }
  }, [SUBSCRIPTION_NAME, EXPIRATION_DATE, SUBSCRIPTION_STATUS]);
  useEffect(()=> {
    if(code && code === 'true') {
      setProModalOpen(true)
    }
  },[code])

  useEffect(() => {
    if (
      (SUBSCRIPTION_NAME !== "undefined" &&
        SUBSCRIPTION_NAME !== "Roundtrip Pro") ||
      (typeof USER_PLAN !== "undefined" && !USER_PLAN)
    ) {
      setIsRegular(true);
    }
    else {
      setIsRegular(false);

    }
  }, [USER_PLAN, SUBSCRIPTION_NAME]);


  return (
    <>
      <ListWrapper>
        {!USER && (
          <a href="https://transconnector.eu/sign-up">
            <NoticeWrapper>
              <img src={usersAdd} />
              <h3>Sign up for free and get unlimited access to Roundtrips</h3>
              <div className="action">
                <button
                  style={{ fontWeight: '700', background: '#00b27e', border: '2px solid #00b27e', padding: '0.5rem 1.15rem', borderRadius: '6px', color: '#fff' }}
                  onClick={() => {
                    window.location.href = "https://transconnector.eu/sign-up"
                  }}
                >Sign up now</button>
              </div>
            </NoticeWrapper>
          </a>
        )}
        <PageTitle>
          <h1>All </h1>
          <LinkWrapper>
            {USER && !isExpired && !isRegular ? (
              <CreateButton
                title="Create Roundtrip Request"
                to="/dashboard/find-roundtrip/create"
              />
            ) : USER && isRegular ? (
              <CreateButton
                title="Create New"
                isButton
                onClick={() => setProModalOpen(true)}
              />
            ) : USER && isExpired ? (
              <CreateButton
                title="Create New"
                isButton
                onClick={() => setExpiredModalOpen(true)}
              />
            ) : (
              <CreateButton
                title="Create New"
                isButton
                onClick={() => setLoggedModalOpen(true)}
              />
            )
            }
          </LinkWrapper>
        </PageTitle>
        <ListHeader>
          <ListHeaderItem flex="0.4">Countries</ListHeaderItem>
          <ListHeaderItem flex="0.4">Truck type</ListHeaderItem>
          <ListHeaderItem flex="0.4">Languages</ListHeaderItem>
          <ListHeaderItem flex="0.3">Published/Edited</ListHeaderItem>
          <ListHeaderItem flex="0.3">Publisher</ListHeaderItem>
        </ListHeader>
        <ListItems>{props.children}</ListItems>
      </ListWrapper>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
      {proModalOpen && <ProGateway onClose={() => setProModalOpen(false)} />}
    </>
  );
};

export default RoundtripListHeader;
