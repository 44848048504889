import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ListWrapper,
  PageTitle,
  LinkWrapper,
  ListItems,
  ListHeaderItem,
} from "../../PageWithList/List/Styles";
import Loader from "../../../components/Dashboard/UI/Loader";
import ListHeader from "../../PageWithList/List/ListHeader";
import CreateButton from "../../Dashboard/UI/CreateButton";
import { isBrowser } from "react-device-detect";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import VerificationNotice from "../../Modals/AuthGatewayModals/VerificationNotice";

import ActionButton from "../../PageWithList/Sheet/ActionButton";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import { NoticeWrapper } from "../../UI/SignUpNotice/styles";
import usersAdd from "../../../assets/icons/userAdd.svg";

const SpotloadList = (props) => {
  const { USER, COMPANY } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [nonVerifiedModalOpen, setNonVerifiedModalOpen] = useState(false);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  const handleSorting = (type) => {
    if (isBrowser)
      props.setSorting((prevState) => ({
        sortBy: type,
        order:
          prevState.sortBy !== type
            ? "DESC"
            : prevState.order === "DESC"
            ? "ASC"
            : "DESC",
      }));
  };

  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired"))
      setIsExpired(true);
  }, [USER_PLAN]);

  if (USER && !COMPANY) {
    return <Loader />;
  }

  return (
    <>
      <ListWrapper>
        {!USER && (
          <a href="https://transconnector.eu/sign-up">
            <NoticeWrapper>
              <img src={usersAdd} />
              <h3>
                Sign up for free and get unlimited access to Load Exhcange
              </h3>
              <div className="action">
                <button
                  style={{
                    fontWeight: "700",
                    background: "#00b27e",
                    border: "2px solid #00b27e",
                    padding: "0.5rem 1.15rem",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    window.location.href = "https://transconnector.eu/sign-up";
                  }}
                >
                  Sign up now
                </button>
              </div>
            </NoticeWrapper>
          </a>
        )}
        <PageTitle>
          <h1>All spot freights</h1>
          <LinkWrapper>
            {USER && COMPANY?.verified ? (
              <CreateButton
                title="Add loads from CSV"
                isButton
                onClick={() => props.setShowUploadModal(true)}
                // to="/spotload/upload"
                white
                noIcon
              />
            ) : USER && !COMPANY?.verified ? (
              <CreateButton
                title="Add loads from CSV"
                isButton
                onClick={() => setNonVerifiedModalOpen(true)}
                white
                noIcon
              />
            ) : (
              <CreateButton
                title="Add loads from CSV"
                isButton
                onClick={() => setLoggedModalOpen(true)}
                white
                noIcon
              />
            )}

            {USER && COMPANY?.verified ? (
              <CreateButton
                title="Create Spot freight"
                to="/dashboard/spotload/create"
              />
            ) : USER && !COMPANY?.verified ? (
              <CreateButton
                title="Create Spot freight"
                isButton
                onClick={() => setNonVerifiedModalOpen(true)}
              />
            ) : (
              <CreateButton
                title="Create Spot freight"
                isButton
                onClick={() => setLoggedModalOpen(true)}
              />
            )}
          </LinkWrapper>
        </PageTitle>
        <ListHeader>
          <ListHeaderItem
            flex="0.5"
            isSortable={props.showLoadingSort}
            onClick={() => props.showLoadingSort && handleSorting("1")}
          >
            Loading
            {isBrowser &&
            props.currentSort?.sortBy === "1" &&
            props.currentSort?.order === "DESC"
              ? props.showLoadingSort && sortIconUp
              : props.showLoadingSort && sortIconDown}
          </ListHeaderItem>
          <ListHeaderItem
            flex="0.5"
            isSortable={props.showUnloadingSort}
            onClick={() => props.showUnloadingSort && handleSorting("2")}
          >
            Unloading
            {isBrowser &&
            props.currentSort?.sortBy === "2" &&
            props.currentSort?.order === "DESC"
              ? props.showUnloadingSort && sortIconUp
              : props.showUnloadingSort && sortIconDown}
          </ListHeaderItem>
          <ListHeaderItem flex="0.4">Truck type</ListHeaderItem>
          <ListHeaderItem
            flex="0.2"
            isSortable
            onClick={() => handleSorting("4")}
          >
            Price
            {isBrowser &&
            props.currentSort?.sortBy === "4" &&
            props.currentSort?.order === "DESC"
              ? sortIconUp
              : sortIconDown}
          </ListHeaderItem>
          <ListHeaderItem
            flex="0.3"
            isSortable
            onClick={() => handleSorting("5")}
          >
            Published
            {isBrowser &&
            props.currentSort?.sortBy === "5" &&
            props.currentSort?.order === "DESC"
              ? sortIconUp
              : sortIconDown}
          </ListHeaderItem>
          <ListHeaderItem flex="0.3">Publisher</ListHeaderItem>
        </ListHeader>
        <ListItems>{props.children}</ListItems>
      </ListWrapper>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
      {nonVerifiedModalOpen && (
        <VerificationNotice onClose={() => setNonVerifiedModalOpen(false)} />
      )}
    </>
  );
};

const sortIconUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path d="M4,0,8,6H0Z" fill="#686a7d" />
  </svg>
);

const sortIconDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path
      d="M4,0,8,6H0Z"
      transform="translate(8 6) rotate(180)"
      fill="#686a7d"
    />
  </svg>
);

export default SpotloadList;
