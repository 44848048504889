import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ListWrapper,
  PageTitle,
  LinkWrapper,
  ListItems,
  ListHeaderItem,
} from "../../PageWithList/List/Styles";
import Loader from "../../../components/Dashboard/UI/Loader";
import ListHeader from "../../PageWithList/List/ListHeader";
import CreateButton from "../../Dashboard/UI/CreateButton";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import VerificationNotice from "../../Modals/AuthGatewayModals/VerificationNotice";

import ActionButton from "../../PageWithList/Sheet/ActionButton";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import { NoticeWrapper } from "../../UI/SignUpNotice/styles";
import usersAdd from "../../../assets/icons/userAdd.svg";
const EmptyTruckList = (props) => {
  const { USER, COMPANY } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [nonVerifiedModalOpen, setNonVerifiedModalOpen] = useState(false);

  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  /*   const handleSorting = (type) => {
    if (isBrowser)
      props.setSorting((prevState) => ({
        sortBy: type,
        order:
          prevState.sortBy !== type
            ? "DESC"
            : prevState.order === "DESC"
            ? "ASC"
            : "DESC",
      }));
  }; */

  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired"))
      setIsExpired(true);
  }, [USER_PLAN]);

  if (USER && !COMPANY) {
    return <Loader />;
  }

  return (
    <>
      <ListWrapper>
        {!USER && (
          <a href="https://transconnector.eu/sign-up">
            <NoticeWrapper>
              <img src={usersAdd} />
              <h3>Sign up for free and get unlimited access to Empty Trucks</h3>
              <div className="action">
                <button
                  style={{
                    fontWeight: "700",
                    background: "#00b27e",
                    border: "2px solid #00b27e",
                    padding: "0.5rem 1.15rem",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    window.location.href = "https://transconnector.eu/sign-up";
                  }}
                >
                  Sign up now
                </button>
              </div>
            </NoticeWrapper>
          </a>
        )}
        <PageTitle>
          <h1>All empty trucks</h1>
          <LinkWrapper>
            {USER && COMPANY?.verified ? (
              <CreateButton
                title="Create Empty truck"
                to="/dashboard/trucks/create"
              />
            ) : USER && !COMPANY?.verified ? (
              <CreateButton
                title="Create Empty truck"
                isButton
                onClick={() => setNonVerifiedModalOpen(true)}
              />
            )
             : (
              <CreateButton
                title="Create Empty truck"
                isButton
                onClick={() => setLoggedModalOpen(true)}
              />
            )}
          </LinkWrapper>
        </PageTitle>
        <ListHeader>
          <ListHeaderItem flex="0.4">From</ListHeaderItem>
          <ListHeaderItem flex="0.4">To</ListHeaderItem>
          <ListHeaderItem flex="0.4">Truck type</ListHeaderItem>
          <ListHeaderItem flex="0.3">Available for roundtrips</ListHeaderItem>
          <ListHeaderItem flex="0.4">Special features</ListHeaderItem>
          <ListHeaderItem flex="0.3">Publisher</ListHeaderItem>
        </ListHeader>
        <ListItems>{props.children}</ListItems>
      </ListWrapper>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
      {nonVerifiedModalOpen && (
        <VerificationNotice onClose={() => setNonVerifiedModalOpen(false)} />
      )}
    </>
  );
};

/* const sortIconUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path d="M4,0,8,6H0Z" fill="#686a7d" />
  </svg>
);

const sortIconDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path
      d="M4,0,8,6H0Z"
      transform="translate(8 6) rotate(180)"
      fill="#686a7d"
    />
  </svg>
); */

export default EmptyTruckList;
