import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpotloadListHeader from "./SpotloadListHeader";
import SpotloadListItem from "./SpotloadListItem";
import Loader from "../../Dashboard/UI/Loader";
import {
  filterTruckTypes,
  filterLocations,
} from "../../../helpers/ListFiltering";
import UserNotice from "../../UserNotice/UserNotice";
import welcomeNoResults from "../../../assets/welcome/noResults.svg";
import { getFilterSpotloads } from "../../../store/actions/app/spotload";
import ReviewModal from "../../Modals/ReviewModal/ReviewModal";
import AddReview from "../../Modals/ReviewModal/AddReview";
import { TextButton } from "../../Buttons/NewButton/Styles";

const SpotloadListWrapper = React.memo((props) => {
  const { USER, COMPANY } = props;
  const [spotloads, setSpotloads] = useState([]);
  const [hasSetAllSpotloads, setHasSetAllSpotloads] = useState(false);
  const [sorting, setSorting] = useState({
    sortBy: "5",
    order: "ASC",
  });
  const [reviewModal, setReviewModal] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Spotload list filtering
  const {
    loading,
    loadingradius,
    loadingDate,
    unloading,
    unloadingradius,
    unloadingDate,
    truckType,
    typeOfLoad,
  } = props.filters;
  const { setAllSpotloads } = props;

  const fetchSpotloads = (newSkip = skip, reset = false) => {
    let didCancel = false;
    const truckTypes = filterTruckTypes(truckType);
    const params = filterLocations(
      loading,
      loadingradius,
      unloading,
      unloadingradius
    );

    dispatch(
      getFilterSpotloads({
        loadingLocation: params.loading.value,
        loadingRadius: params.loading.radius,
        loadingDateStart: loadingDate?.startDate,
        loadingDateEnd: loadingDate?.endDate,
        unloadingLocation: params.unloading.value,
        unloadingRadius: params.unloading.radius,
        unloadingDateStart: unloadingDate?.startDate,
        unloadingDateEnd: unloadingDate?.endDate,
        truckType: truckTypes,
        typeOfLoad: typeOfLoad,
        sortby: sorting.sortBy,
        order: sorting.order,
        limit: limit,
        skip: reset ? 0 : newSkip,
      })
    )
      .then((res) => {
        if (!didCancel) {
          let result;
          res.data ? (result = res.data.reverse()) : (result = null);
          if (reset) {
            setSpotloads(result);
          } else {
            setSpotloads((prevSpotloads) => [...prevSpotloads, ...result]);
          }
          setHasMore(result.length === limit);
          const hasNoMore = result.length < limit;
          if (!hasSetAllSpotloads && !hasNoMore) {
            setAllSpotloads([...spotloads, ...result]);
          }
          if (hasNoMore) {
            setAllSpotloads([...spotloads, ...result]);
            setHasSetAllSpotloads(true);
          }
        }
      })
      .catch((err) => console.log(err));
    return () => (didCancel = true);
  };

  useEffect(() => {
    if (props.filters) {
      fetchSpotloads(0, true);
      setSkip(0);
    }
  }, [
    props.filters,
    loading,
    loadingradius,
    loadingDate,
    unloading,
    unloadingradius,
    unloadingDate,
    truckType,
    typeOfLoad,
    sorting,
  ]);

  const loadMore = () => {
    const newSkip = skip + limit;
    setSkip(newSkip);
    fetchSpotloads(newSkip);
  };

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired"))
      setIsExpired(true);
  }, [USER_PLAN]);

  // Add Sheet ID to localstorage to display viewed spotloads
  useEffect(() => {
    if (props.sheetID && USER && !isExpired) {
      const viewedSpotloads = localStorage.hasOwnProperty("viewedSpotloads")
        ? JSON.parse(localStorage.getItem("viewedSpotloads"))
        : [];
      if (!viewedSpotloads.includes(props.sheetID)) {
        viewedSpotloads.push(props.sheetID);
        localStorage.setItem(
          "viewedSpotloads",
          JSON.stringify(viewedSpotloads)
        );
      }
    }
  }, [props.sheetID, USER, isExpired]);

  return (
    <SpotloadListHeader
      showLoadingSort={props.filters?.loading?.value}
      showUnloadingSort={props.filters?.unloading?.value}
      currentSort={sorting}
      setSorting={setSorting}
      USER={USER}
      COMPANY={COMPANY}
      setShowUploadModal={props.setShowUploadModal}
    >
      {spotloads?.length > 0
        ? spotloads?.map((spotload) => {
            return (
              <SpotloadListItem
                USER={USER}
                COMPANY={COMPANY}
                key={spotload._id}
                spotload={spotload}
                sheetID={props.sheetID === spotload._id ? props.sheetID : null}
                setSheetID={props.setSheetID}
                viewed={
                  localStorage.hasOwnProperty("viewedSpotloads") &&
                  localStorage.getItem("viewedSpotloads").includes(spotload._id)
                }
                setReviewModal={setReviewModal}
                location={
                  props.filters?.loading?.value ||
                  props.filters?.unloading?.value
                    ? {
                        loading: props.filters?.loading?.value,
                        unloading: props.filters?.unloading?.value,
                      }
                    : null
                }
              />
            );
          })
        : spotloads && <Loader />}
      {!spotloads && (
        <UserNotice
          image={welcomeNoResults}
          title="Unfortunately no spot freights were found. Try again with different filters or clear all filters with the button below."
          buttons={[
            {
              name: "Clear filters",
              isButton: true,
              onClick: props.clearFilters,
            },
          ]}
        />
      )}
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: "1rem", width: "100%" }}>
        <TextButton onClick={loadMore}>Load more</TextButton>
        </div>
      )}
      {reviewModal && (
        <ReviewModal
          showHeader
          fetchReviews
          id={reviewModal}
          onClose={() => setReviewModal(null)}
          onClick={() => {
            setAddReviewModal(reviewModal);
            setReviewModal(null);
          }}
        />
      )}
      {!reviewModal && addReviewModal && (
        <AddReview
          name={reviewModal?.company?.companyName}
          id={addReviewModal}
          onClose={() => setAddReviewModal(false)}
        />
      )}
    </SpotloadListHeader>
  );
});

export default SpotloadListWrapper;