import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyTruckListHeader from './EmptyTruckListHeader';
import EmptyTruckListItem from './EmptyTruckListItem';
import Loader from '../../Dashboard/UI/Loader';
import {
  filterTruckTypes,
  filterLocations,
} from '../../../helpers/ListFiltering';
import UserNotice from '../../UserNotice/UserNotice';
import welcomeNoResults from '../../../assets/welcome/noResults.svg';
import { getEmptyTruckFilters } from '../../../store/actions/app/emptytruck';
import ReviewModal from '../../Modals/ReviewModal/ReviewModal';
import AddReview from '../../Modals/ReviewModal/AddReview';
import { TextButton } from "../../Buttons/NewButton/Styles";

const EmptyTruckListWrapper = React.memo((props) => {
  const { USER, COMPANY } = props;
  const [trucks, setTrucks] = useState([]);
  const [hasSetAllTrucks, setHasSetAllTrucks] = useState(false);
  const [sorting] = useState({
    sortBy: '5',
    order: 'ASC',
  });
  const [reviewModal, setReviewModal] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Truck list filtering
  const {
    loadingLocation,
    loadingRadius,
    date,
    unloadingLocation,
    unloadingRadius,
    truckType,
    typeOfLoad,
    specialFeatures,
    availableForRoundtrips,
  } = props.filters;
  const { setAllTrucks } = props;

  const fetchTrucks = (newSkip = skip, reset = false) => {
    let didCancel = false;
    const truckTypes = filterTruckTypes(truckType);
    const specialFtrs = filterTruckTypes(specialFeatures);
    const params = filterLocations(
      loadingLocation,
      loadingRadius,
      unloadingLocation,
      unloadingRadius
    );

    dispatch(
      getEmptyTruckFilters({
        loadingLocation: params.loading.value,
        loadingRadius: params.loading.radius,
        unloadingLocation: params.unloading.value,
        unloadingRadius: params.unloading.radius,
        startDate: date?.startDate,
        endDate: date?.endDate,
        truckType: truckTypes,
        typeOfLoad: typeOfLoad,
        specialFeatures: specialFtrs,
        availableForRoundtrips,
        limit: limit,
        skip: reset ? 0 : newSkip,
      })
    )
      .then((res) => {
        if (!didCancel) {
          const result = res?.data?.data;
          if (reset) {
            setTrucks(result);
          } else {
            setTrucks((prevTrucks) => [...prevTrucks, ...result]);
          }
          setHasMore(result.length === limit);
          const hasNoMore = result.length < limit;
          if (!hasSetAllTrucks && !hasNoMore) {
            setAllTrucks([...trucks, ...result]);
          }
          if (hasNoMore) {
            setAllTrucks([...trucks, ...result]);
            setHasSetAllTrucks(true);
          }
        }
      })
      .catch((err) => console.log(err));
    return () => (didCancel = true);
  };

  useEffect(() => {
    if (props.filters) {
      fetchTrucks(0, true);
      setSkip(0);
    }
  }, [
    props.filters,
    loadingLocation,
    loadingRadius,
    date,
    unloadingLocation,
    unloadingRadius,
    truckType,
    typeOfLoad,
    sorting,
  ]);

  const loadMore = () => {
    const newSkip = skip + limit;
    setSkip(newSkip);
    fetchTrucks(newSkip);
  };

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === 'Expired'))
      setIsExpired(true);
  }, [USER_PLAN]);

  // Add Sheet ID to localstorage to display viewed trucks
  useEffect(() => {
    if (props.sheetID && USER && !isExpired) {
      const viewedTrucks = localStorage.hasOwnProperty('viewedTrucks')
        ? JSON.parse(localStorage.getItem('viewedTrucks'))
        : [];
      if (!viewedTrucks.includes(props.sheetID)) {
        viewedTrucks.push(props.sheetID);
        localStorage.setItem('viewedTrucks', JSON.stringify(viewedTrucks));
      }
    }
  }, [props.sheetID, USER, isExpired]);

  return (
    <EmptyTruckListHeader USER={USER} COMPANY={COMPANY}>
      {trucks?.length > 0 ? (
        trucks?.map((truck) => {
          return (
            <EmptyTruckListItem
              USER={USER}
              COMPANY={COMPANY}
              key={truck._id}
              truck={truck}
              sheetID={props.sheetID === truck._id ? props.sheetID : null}
              setSheetID={props.setSheetID}
              viewed={
                localStorage.hasOwnProperty('viewedTrucks') &&
                localStorage.getItem('viewedTrucks').includes(truck._id)
              }
              setReviewModal={setReviewModal}
              location={
                props.filters?.loading?.value || props.filters?.unloading?.value
                  ? {
                      loading: props.filters?.loading?.value,
                      unloading: props.filters?.unloading?.value,
                    }
                  : null
              }
            />
          );
        })
      ) : !trucks ? (
        <Loader />
      ) : (
        Object.keys(props.filters)?.length <= 0 &&
        trucks?.length <= 0 && <h3>Nothing to show yet...</h3>
      )}
      {trucks?.length <= 0 && Object.keys(props.filters)?.length > 0 && (
        <UserNotice
          image={welcomeNoResults}
          title="Unfortunately no empty trucks were found. Try again with different filters or clear all filters with the button below."
          buttons={[
            {
              name: 'Clear filters',
              isButton: true,
              onClick: props.clearFilters,
            },
          ]}
        />
      )}
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: "1rem", width: "100%" }}>
        <TextButton onClick={loadMore}>Load more</TextButton>
        </div>
      )}
      {reviewModal && (
        <ReviewModal
          showHeader
          fetchReviews
          id={reviewModal}
          onClose={() => setReviewModal(null)}
          onClick={() => {
            setAddReviewModal(reviewModal);
            setReviewModal(null);
          }}
        />
      )}
      {!reviewModal && addReviewModal && (
        <AddReview
          name={reviewModal?.company?.companyName}
          id={addReviewModal}
          onClose={() => setAddReviewModal(false)}
        />
      )}
    </EmptyTruckListHeader>
  );
});

export default EmptyTruckListWrapper;